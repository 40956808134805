import React from 'react'

import styled from 'styled-components'
import colors from 'src/assets/styles/colors'
import mediaQuery from 'src/assets/styles/mediaQuery'
import typographySizes from 'src/assets/styles/typographySizes'
import MainWrapper from 'src/components/global/MainWrapper.js'
import TextBarAnimation from '/src/components/global/TextBarAnimationBig.js'

import Seo from 'src/components/global/Seo.js'

import TitleH1 from 'src/components/global/texts/TitleH1.js'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

const StyledMain = styled.main`
  background-color: ${colors.mainColor};
  width: 100vw;
  position: relative;
  padding-top: 70rem;
  ${MainWrapper} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 125rem;
  }
  ${TitleH1} {
    width: 80vw;
    max-width: 900rem;
    text-align: center;
    z-index: 10;
    font-size: ${typographySizes.m}rem;
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: ${typographySizes.m}rem;
      width: calc(100% - 20rem);
    }
    a {
      font-size: inherit;
      color: inherit;
      :hover {
        color: ${colors.mainColor};
      }
    }
  }
`

//hide scrollbar in iframe
const StyledIframe = styled.iframe`
  width: 100%;
  border: 0px;
  outline: 0px;
  height: calc(100vh - 70rem);
  &::webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta property="og:image" content="https://nobocoto.pl/thumbnail.png" />
      </Helmet>
      <Seo
        title="KONSULTACJE"
        description="Umów konsultację z realizatorem NOBOCOTO"
      />
      <StyledMain>
        {/* <FrameWrapper /> */}
        {/* <TextBarAnimation>umów konsultację</TextBarAnimation> */}

        <StyledIframe
          id="konsultacje"
          src="https://nbct-forms.vercel.app/konsultacje"
          width="100%"
        >
          {' '}
        </StyledIframe>
      </StyledMain>
    </>
  )
}

export default IndexPage

function FrameWrapper() {
  const iframeParent = React.useRef()
  const [height, setHeight] = React.useState(undefined)

  useEffect(() => {
    const handleReceiveMessage = event => {
      const eventName = event?.data?.eventName
      const payload = event?.data?.payload

      if (eventName === 'SET_HEIGHT' && payload?.height) {
        console.log(payload.height)
        setHeight(payload.height)
      }
    }

    window.addEventListener('message', handleReceiveMessage)
    return () => {
      window.removeEventListener('message', handleReceiveMessage)
    }
  })

  return (
    <iframe
      id="konsultacje"
      src="http://localhost:3000"
      width="100%"
      style={{
        width: '100%',
        border: '0px',
        outline: '0px',
        height: height ? `${height}px` : '100vh',
        position: 'relative',
      }}
    ></iframe>
  )
}
